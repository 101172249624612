import { SvgIcon } from '@material-ui/core';

export default function CC0() {
  return (
    <SvgIcon viewBox="0 0 64 64">
      <path d="m32 13.58c-10.564 0-13.22 9.97-13.22 18.42-0.002 8.452 2.66 18.42 13.22 18.42 10.565 0 13.22-9.97 13.22-18.42s-2.655-18.42-13.22-18.42zm0 6.95c0.43 0 0.82 0.06 1.19 0.15 0.76 0.66 1.13 1.564 0.4 2.83l-7.034 12.926c-0.216-1.636-0.246-3.24-0.246-4.436 0-3.723 0.257-11.474 5.69-11.474zm5.267 5.957c0.433 1.983 0.433 4.056 0.433 5.513 0 3.72-0.26 11.475-5.7 11.475-0.425 0-0.82-0.045-1.185-0.135-0.075-0.022-0.135-0.04-0.205-0.07-0.11-0.03-0.23-0.07-0.333-0.11-1.21-0.513-1.972-1.444-0.877-3.09l7.867-13.58z" />
      <path d="m31.933 0c-8.873 0-16.359 3.09-22.453 9.3-3.09 3.09-5.444 6.6-7.08 10.53-1.6 3.89-2.4 7.94-2.4 12.17 0 4.27 0.8 8.32 2.4 12.17s3.92 7.31 6.97 10.4c3.08 3.04 6.545 5.39 10.39 7.03 3.89 1.6 7.94 2.4 12.17 2.4s8.34-0.83 12.31-2.46c3.96-1.64 7.49-4 10.62-7.09 3.01-2.93 5.29-6.293 6.81-10.1 1.56-3.85 2.33-7.97 2.33-12.35 0-4.34-0.77-8.45-2.33-12.3-1.562-3.888-3.85-7.323-6.86-10.333-6.285-6.247-13.92-9.367-22.88-9.367zm0.134 5.76c7.238 0 13.413 2.57 18.553 7.71 2.48 2.48 4.38 5.308 5.671 8.47 1.299 3.16 1.949 6.54 1.949 10.06 0 7.35-2.515 13.45-7.51 18.33-2.59 2.52-5.5 4.448-8.73 5.78-3.21 1.34-6.5 1.996-9.933 1.996-3.067 0-6.788-0.653-9.949-1.946-3.158-1.336-6.001-3.24-8.518-5.72-2.513-2.51-4.45-5.35-5.824-8.51-1.336-3.2-2.016-6.5-2.016-9.93 0-3.47 0.68-6.79 2.02-9.95 1.37-3.2 3.31-6.075 5.82-8.63 4.99-5.03 11.15-7.66 18.467-7.66z" />
    </SvgIcon>
  );
}
